import { axios } from "../../utils/axios";
import ClientSafeException from "../../exceptions/clientSafeException";

export default {
  namespaced: false,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async requestSearchUsers(context, name) {
      let { data, status } = await axios.get("backoffice/users/search", {
        timeout: 10000,
        params: {
          search: name,
          sensor: false,
          limit: 10,
        },
      });

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }

      return data.response;
    },
  },
};
