import { graphQLClient } from "../../utils/graphQLClient";
import optixSearchQuery from "../../graphql/queries/optixSearchQuery.graphql";
import ClientSafeException from "../../exceptions/clientSafeException";

export default {
  namespaced: false,
  state: {
    results: null,
  },
  mutations: {
    MUTATE_RESULTS(state, results) {
      state.results = results;
    },
  },
  getters: {
    getSearchResults: state => state.results,
  },
  actions: {
    async requestOptixSearch({ commit }, term) {
      if (!term) {
        commit("MUTATE_RESULTS", null);
        return;
      }

      let data = await graphQLClient.request(optixSearchQuery, {
        term,
      });

      if (!data.optixSearch) {
        throw ClientSafeException("Oops... could not perform search");
      }

      commit("MUTATE_RESULTS", JSON.parse(JSON.parse(data.optixSearch)));
    },
  },
};
