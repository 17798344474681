<template>
  <v-menu rounded offset-overflow>
    <template v-slot:activator="{ attrs, on }">
      <v-avatar color="primary" size="36" v-on="on" v-bind="attrs">
        <span class="white--text">{{ firstLetterOfName }}</span>
      </v-avatar>
    </template>

    <v-list dense>
      <v-list-item link>
        <v-list-item-title>My account</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="logout">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { showSuccess } from "../utils/toasted";
import { mapGetters } from "vuex";

export default {
  name: "UserMenu",
  methods: {
    logout() {
      this.$store.dispatch("logout");
      showSuccess("See you soon");
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    ...mapGetters(["firstLetterOfName"]),
  },
};
</script>
