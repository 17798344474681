import billingRestrictionsOverrideMutation from "@/graphql/mutations/billingRestrictionsOverrideMutation.graphql";
import ClientSafeException from "@/exceptions/clientSafeException";
import fileUploadMutation from "@/graphql/mutations/fileUploadMutation.graphql";
import forceSyncBillingMutation from "@/graphql/mutations/billingSyncMutation.graphql";
import locationDeleteMutation from "@/graphql/mutations/locationDeleteMutation.graphql";
import mobileAppSettingsUpdateMutation from "@/graphql/mutations/mobileAppSettingsUpdateMutation.graphql";
import optixAccountUpdateMutation from "@/graphql/mutations/optixAccountUpdateMutation.graphql";
import optixCustomerQuery from "@/graphql/queries/optixCustomerQuery.graphql";
import organizationSetCustomerMutation from "@/graphql/mutations/organizationSetCustomerMutation.graphql";
import organizationUnsetCustomerMutation from "@/graphql/mutations/organizationUnsetCustomerMutation.graphql";
import organizationUpdateFeaturesMutation from "@/graphql/mutations/organizationUpdateFeaturesMutation.graphql";
import { axios } from "@/utils/axios";
import { graphQLClient } from "@/utils/graphQLClient";

export default {
  namespaced: false,
  state: {
    organization: null,
    customer: null,
  },
  mutations: {
    MUTATE_ORGANIZATION(state, organization) {
      state.organization = organization;
    },
    MUTATE_MEMBERSHIP(state, membership) {
      state.organization.membership = membership;
    },
    MUTATE_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    MUTATE_MEMBERSHIP_SETTING(state, setting) {
      Object.assign(state.organization.membership, setting);
    },
  },
  getters: {
    getOrganization: state => state.organization,
    getCustomer: state => state.customer,
  },
  actions: {
    async requestOrganizationList(context, params) {
      let { data, status } = await axios.get("backoffice/optix/memberships", {
        params,
      });

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }

      return data.response;
    },
    async requestOrganizationDetails({ commit }, organization_id) {
      let { data, status } = await axios.get(
        "backoffice/venues/get/" + organization_id
      );

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }

      commit("MUTATE_ORGANIZATION", data.response);
    },
    async requestUpdateMembershipViaV1({ commit, state }, payload) {
      let { data, status } = await axios.post(
        "backoffice/optix/updateAccount/",
        {
          ...state.organization.membership,
          ...payload,
        }
      );

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }

      commit("MUTATE_MEMBERSHIP", data.response.membership);
    },
    async requestUpdateMembershipViaV2({ commit, state }, payload) {
      let response = await graphQLClient.request(optixAccountUpdateMutation, {
        organization_id: state.organization.venue_id,
        ...payload,
      });

      commit("MUTATE_MEMBERSHIP", {
        ...state.organization.membership,
        ...response.optixAccountUpdate,
      });
    },
    requestUpdateMobileAppViaV2({ state }, payload) {
      return graphQLClient.request(mobileAppSettingsUpdateMutation, {
        organization_id: state.organization.venue_id,
        ...payload,
      });
    },
    requestFileUpload({ state }, payload) {
      return graphQLClient.request(fileUploadMutation, {
        organization_id: state.organization.venue_id,
        ...payload,
      });
    },
    async requestUpdateOrganizationFeaturesFlag({ commit, state }, setting) {
      let response = await graphQLClient.request(
        organizationUpdateFeaturesMutation,
        {
          organization_id: state.organization.venue_id,
          [setting.flag]: setting.value,
        }
      );

      commit("MUTATE_MEMBERSHIP_SETTING", {
        [setting.flag]: response.organizationUpdateFeatures.features[
          setting.flag
        ]
          ? 1
          : 0,
      });
    },
    async requestOptixCustomer({ commit, state }, organization_id) {
      if (
        state.customer &&
        state.customer.main_organization.organization_id == organization_id
      ) {
        return;
      }

      let { optixCustomer } = await graphQLClient.request(optixCustomerQuery, {
        organization_id,
      });

      commit("MUTATE_CUSTOMER", optixCustomer);
    },
    async requestLinkStripe({ commit }, { organization_id, customer_id }) {
      let { organizationSetCustomer } = await graphQLClient.request(
        organizationSetCustomerMutation,
        {
          organization_id,
          customer_id,
        }
      );

      if (!organizationSetCustomer) {
        throw new ClientSafeException("Oops... couldn't link Stripe customer");
      }

      commit("MUTATE_CUSTOMER", organizationSetCustomer);
    },
    async requestUnlinkStripe({ commit }, { organization_id }) {
      let { organizationUnsetCustomer } = await graphQLClient.request(
        organizationUnsetCustomerMutation,
        {
          organization_id,
        }
      );

      if (!organizationUnsetCustomer) {
        throw new ClientSafeException("Oops... couldn't unlink Stripe customer");
      }

      commit("MUTATE_CUSTOMER", null);
    },
    async requestDeleteLocation(context, location_id) {
      let { locationDelete } = await graphQLClient.request(
        locationDeleteMutation,
        {
          location_id,
        }
      );

      if (!locationDelete) {
        throw new ClientSafeException("Oops... couldn't delete location");
      }

      return locationDelete;
    },
    async requestOverrideBillingRestrictions(
      context,
      { optix_billing_customer_id, input }
    ) {
      let { billingRestrictionsOverride } = await graphQLClient.request(
        billingRestrictionsOverrideMutation,
        {
          optix_billing_customer_id,
          input,
        }
      );

      if (!billingRestrictionsOverride) {
        throw new ClientSafeException(
          "Oops... couldn't override billing restrictions"
        );
      }

      return billingRestrictionsOverride.restriction_overrides;
    },
    async requestBillingSync(context, organization_id) {
      let { billingSync } = await graphQLClient.request(
        forceSyncBillingMutation,
        {
          organization_id,
        }
      );

      if (!billingSync) {
        throw new ClientSafeException("Oops... couldn't sync billing");
      }

      return billingSync;
    },
    async requestAddAdmin(context, { user_id, organization_id, minutes = 30 }) {
      let { data, status } = await axios.post("backoffice/venues/adminAdd/", {
        user_id,
        venue_id: organization_id,
        minutes,
        visible: 0,
      });

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }
    },
    async requestResetTrial(context, { membership_id, days }) {
      let { data, status } = await axios.get("backoffice/optix/resetTrial", {
        params: {
          membership_id,
          days,
        },
      });

      if (status !== 200 || data.status.code !== 200) {
        throw new ClientSafeException("Couldn't get response from API");
      }
    },
  },
};
