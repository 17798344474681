export default {
  namespaced: false,
  state: {
    isNavVisible: true,
  },
  mutations: {
    UPDATE_NAV(state, isVisible) {
      state.isNavVisible = isVisible;
    },
  },
  getters: {},
  actions: {
    updateNav({ commit }, isVisible) {
      commit("UPDATE_NAV", isVisible);
    },
  },
};
