import axiosModule from "axios";
import store from "../store";
import router from "../router";
import { showError } from "./toasted";

export const getAxiosUrl = () => {
  let apiV1 = {
    local: "http://sharedesk.local/api/",
    staging: "https://staging.catalufa.net/api/",
    production: "https://www.sharedesk.net/api/",
  };

  return apiV1[process.env.VUE_APP_ENVIRONMENT];
};

export const axios = axiosModule.create({
  baseURL: getAxiosUrl(),
  timeout: 30000,
});

axios.interceptors.request.use(
  function(config) {
    if (store.state.auth.access_token) {
      config.headers["access_token"] = store.state.auth.access_token;
    }

    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async function(response) {
    if (response.data.status && response.data.status.code === 401) {
      await store.dispatch("logout");
      showError("Oops... We couldn't find your credentials");
      await router.push({ name: "login" });
      return;
    }

    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);
