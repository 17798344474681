import { GraphQLClient } from "graphql-request";
import store from "../store";

const getGraphQLUrl = () => {
  let apiV2 = {
    local: "http://api.sharedesk.local/graphql",
    staging: "https://api.catalufa.net/graphql?debug=43209482049",
    production: "https://api.optixapp.com/graphql",
  };

  return apiV2[process.env.VUE_APP_ENVIRONMENT];
};

export const graphQLClient = new GraphQLClient(getGraphQLUrl(), {
  mode: "cors",
});

let originalRequestMethod = graphQLClient.__proto__.request;

// Overrides request method to add access token on-the-fly
graphQLClient.__proto__.request = function(
  document,
  variables,
  requestHeaders
) {
  this.setHeader("authorization", "Bearer " + store.state.auth.access_token);

  return originalRequestMethod.call(this, document, variables, requestHeaders);
};
