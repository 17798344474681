import Vue from "vue";
import Toasted from "vue-toasted";
Vue.use(Toasted);

const defaultOptions = {
  position: "top-center",
  iconPack: "mdi",
  theme: "bubble",
  duration: 2000,
};

Vue.toasted.register("showError", message => message, {
  type: "error",
  icon: "mdi-alert-outline",
  ...defaultOptions,
});

Vue.toasted.register("showSuccess", message => message, {
  type: "success",
  icon: "mdi-check",
  ...defaultOptions,
});

Vue.toasted.register("showInfo", message => message, {
  type: "info",
  icon: "mdi-info",
  ...defaultOptions,
});

export const showError = message => {
  console.error(message);
  return Vue.toasted.global.showError(message);
};

export const showSuccess = message => Vue.toasted.global.showSuccess(message);
export const showInfo = message => Vue.toasted.global.showInfo(message);
