import store from "../../store";

export default async (to, from, next) => {
  if (store.getters.isAuthenticated === false) {
    await store.dispatch("loginFromCookie");
  }

  if (to.meta.public) {
    return next();
  }

  if (store.getters.isAuthenticated === false) {
    return next({ name: "login" });
  }

  next();
};
