<template>
  <v-app>
    <the-navigation></the-navigation>

    <v-main>
      <the-app-bar></the-app-bar>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TheNavigation from "../components/TheNavigation";
import TheAppBar from "../components/TheAppBar";
export default {
  components: { TheAppBar, TheNavigation },
};
</script>
