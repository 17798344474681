<template>
  <v-app-bar dark class="d-lg-none" color="#151515">
    <v-app-bar-nav-icon @click="showNavigation"></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
export default {
  name: "TheAppBar",
  methods: {
    showNavigation() {
      this.$store.dispatch("updateNav", true);
    },
  },
};
</script>
