import { axios } from "../../utils/axios";

export default {
  namespaced: false,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async requestAdmins() {
      let { data } = await axios.get("backoffice/admins/search");

      return data.response.admins;
    },
  },
};
