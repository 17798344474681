<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        :class="{ 'v-list-item--active': active }"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...(tooltip ? on : {}) }"
      >
        <v-list-item-icon v-if="icon">
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title><slot></slot></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <span><slot></slot></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    tooltip: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
.v-tooltip__content {
  background: #151515 !important;
  font-weight: 500;
}
</style>
