<template>
  <v-navigation-drawer
    @input="updateDrawer"
    :value="isVisible"
    app
    dark
    color="#151515"
    mini-variant
  >
    <v-list dense nav class="pt-6">
      <nav-item icon="mdi-magnify" link :to="{ name: 'search' }"
        >Search</nav-item
      >
      <v-divider class="my-2"></v-divider>
      <nav-item
        icon="mdi-domain"
        link
        :to="{ name: 'organizations.list' }"
        :active="isOrgNavActive"
        >Organizations</nav-item
      >
      <nav-item
        icon="mdi-account-circle"
        link
        :to="{ name: 'users.list' }"
        :active="isUsersNavActive"
        >Users</nav-item
      >
      <nav-item
        icon="mdi-hammer-wrench"
        link
        :to="{ name: 'tools.tos' }"
        :active="isToolsNavActive"
        >Tools</nav-item
      >
      <nav-item
        icon="mdi-code-tags"
        link
        :to="{ name: 'dev.apps' }"
        :active="isDevNavActive"
        >Dev zone</nav-item
      >
      <nav-item
        icon="mdi-cogs"
        link
        :to="{ name: 'settings.admins' }"
        :active="isSettingsNavActive"
        >Back-office settings</nav-item
      >
    </v-list>

    <template v-slot:append>
      <div class="text-center py-2">
        <user-menu></user-menu>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import NavItem from "../components/dumb/NavItem";
import UserMenu from "../components/UserMenu";
import {
  DEV_NAV,
  ORG_NAV,
  SETTINGS_NAV,
  TOOLS_NAV,
  USERS_NAV,
} from "../utils/valueObjects/nav";
export default {
  components: { UserMenu, NavItem },
  methods: {
    updateDrawer(value) {
      this.$store.dispatch("updateNav", value);
    },
  },
  computed: {
    isVisible() {
      return this.$store.state.ui.isNavVisible;
    },
    isOrgNavActive() {
      return this.$route.meta.activeNav === ORG_NAV;
    },
    isUsersNavActive() {
      return this.$route.meta.activeNav === USERS_NAV;
    },
    isToolsNavActive() {
      return this.$route.meta.activeNav === TOOLS_NAV;
    },
    isDevNavActive() {
      return this.$route.meta.activeNav === DEV_NAV;
    },
    isSettingsNavActive() {
      return this.$route.meta.activeNav === SETTINGS_NAV;
    },
  },
};
</script>
