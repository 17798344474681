import Admin from "../layouts/Admin.vue";
import Auth from "../layouts/Blank.vue";
import {
  DEV_NAV,
  ORG_NAV,
  SETTINGS_NAV,
  TOOLS_NAV,
  USERS_NAV,
} from "../utils/valueObjects/nav";

export default [
  { path: "/", redirect: { name: "login" } },
  {
    path: "/auth",
    component: Auth,
    children: [
      {
        path: "/",
        name: "login",
        meta: {
          public: true,
        },
        component: () =>
          import(/* webpackChunkName: "auth" */ "../views/Login.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "/search",
        name: "search",
        component: () =>
          import(/* webpackChunkName: "dev" */ "../views/Search.vue"),
      },
      {
        path: "/",
        redirect: { name: "organizations.list" },
      },
      {
        path: "/networks",
        name: "networks.list",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/networks/NetworkList.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations",
        name: "organizations.list",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationList.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id",
        name: "organizations.view",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationView.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/billing",
        name: "organizations.view.billing",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationBilling.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/subscription/:subscription_id",
        name: "organizations.stripe.subscription",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/stripe/SubscriptionView.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/settings",
        name: "organizations.view.settings",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationSettings.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/mobile_app",
        name: "organizations.view.mobile_app",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationMobileApp.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/admins",
        name: "organizations.view.admins",
        component: () =>
          import(
            /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationAdmins.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/export_plans",
        name: "organizations.tools.export_plans",
        component: () =>
          import(
            /* webpackChunkName: "organizations.tools" */ "../views/organizations/tools/ExportPlans.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/export_revenue",
        name: "organizations.tools.export_revenue",
        component: () =>
          import(
            /* webpackChunkName: "organizations.tools" */ "../views/organizations/tools/ExportRevenue.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/import_spreedly",
        name: "organizations.tools.import_spreedly",
        component: () =>
          import(
            /* webpackChunkName: "organizations.tools" */ "../views/organizations/tools/ImportSpreedly.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/organizations/:organization_id/sticky_banner",
        name: "organizations.tools.sticky_banner",
        component: () =>
          import(
            /* webpackChunkName: "organizations.tools" */ "../views/organizations/tools/StickyBanner.vue"
          ),
        meta: {
          activeNav: ORG_NAV,
        },
      },
      {
        path: "/users",
        name: "users.list",
        component: () =>
          import(/* webpackChunkName: "dev" */ "../views/users/UserList.vue"),
        meta: {
          activeNav: USERS_NAV,
        },
      },
      {
        path: "/tools/terms-of-service",
        name: "tools.tos",
        component: () =>
          import(
            /* webpackChunkName: "dev" */ "../views/tools/TermsOfService.vue"
          ),
        meta: {
          activeNav: TOOLS_NAV,
        },
      },
      {
        path: "/dev/apps",
        name: "dev.apps",
        component: () =>
          import(/* webpackChunkName: "dev" */ "../views/dev/Apps.vue"),
        meta: {
          activeNav: DEV_NAV,
        },
      },
      {
        path: "/dev/invoices/check",
        name: "dev.invoices.checker",
        component: () =>
          import(
            /* webpackChunkName: "dev" */ "../views/dev/InvoiceChecker.vue"
          ),
        meta: {
          activeNav: DEV_NAV,
        },
      },
      {
        path: "/dev/invoices/warnings",
        name: "dev.invoices.warnings",
        component: () =>
          import(
            /* webpackChunkName: "dev" */ "../views/dev/InvoiceWarnings.vue"
          ),
        meta: {
          activeNav: DEV_NAV,
        },
      },
      {
        path: "/dev/spreedly/gateways",
        name: "dev.spreedly.gateways",
        component: () =>
          import(
            /* webpackChunkName: "dev" */ "../views/dev/SpreedlyGateways.vue"
          ),
        meta: {
          activeNav: DEV_NAV,
        },
      },
      {
        path: "/dev/spreedly/transactions",
        name: "dev.spreedly.transactions",
        component: () =>
          import(
            /* webpackChunkName: "dev" */ "../views/dev/SpreedlyTransactions.vue"
          ),
        meta: {
          activeNav: DEV_NAV,
        },
      },
      {
        path: "/settings/admins",
        name: "settings.admins",
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "../views/settings/Admins.vue"
          ),
        meta: {
          activeNav: SETTINGS_NAV,
        },
      },
    ],
  },
];
